<template>
  <div>
    <v-row>
      <v-col cols="12">
        <SpecificationsTable :itemsPerPage="20" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpecificationsTable from "@/packages/admin/components/specifications/SpecificationsTable";
export default {
  name: "specifications",
  components: { SpecificationsTable },
};
</script>

<style scoped></style>
